import type { InvoiceId, Invoices } from '../../types';
import { useApi } from '@/src/services/network';
import type { Response, InvoiceType } from '~/src/shared/lib';

export type InvoicesDTO = Response<{
	items: {
		id: InvoiceId;
		user: string;
		balance: number;
		type: {
			id: string;
			name: string;
			code: InvoiceType;
			active: string;
		};
		tournament: null;
		real_balance: number;
		bonus?: number | undefined;
		turnover_sum?: number | undefined;
	}[];
	count: number;
}>;

export const toDomain = (data: InvoicesDTO): Invoices =>
	data.result.items.map(item => ({
		id: item.id,
		realBalance: item.real_balance,
		balance: item.balance,
		bonus: item.bonus,
		turnoverSum: item.turnover_sum,
		type: {
			id: item.type.id,
			name: item.type.name,
			code: item.type.code,
		},
		tournament: item.tournament,
	}));

export const useGetInvoices = () => {
	const api = useApi();

	return async () =>
		(await api<InvoicesDTO>('/Invoices/get/')).mapRight(toDomain);
};
